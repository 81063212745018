import "styles/pages/page-company.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import NewContactForm from "components/NewContactForm";
import SectionHeading from "components/SectionHeading";
import SectionWithMenu from "components/SectionWithMenu";
import theme from "theme";

const Company = ({ pageContext }) => {
  const company = pageContext.pageContent;

  const selectableTeamMember = company.sectionOtTeam.map((item, index) => {
    return {
      key: index,
      menuLabel: item.sectionOtMemberName,
      subtitle: item.sectionOtMemberRole,
      name: "About",
      desc: item.sectionOtMemberText,
      image: item.sectionOtMemberImage?.sourceUrl,
    };
  });
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/company-bg.png").default}
      />
      <SectionHeading
        title={company.sectionOtTitle}
        subtitle={company.sectionOtSubtitle}
        margin="both"
      />
      <section className="section-company__team">
        <SectionWithMenu
          items={selectableTeamMember}
          variant="imgLeft"
          bgColor={theme.colors.light}
        />
      </section>
      <NewContactForm />
    </Layout>
  );
};

export default Company;
